import util from "lib/util"

export default{
  name: "orderHistory",
  data() {
    return {
      finish: false,
      detail: {},
      showReason:false,
      lists: [],
      showLoading: true,
      emptyTips: '',
      sntSign: util.query("sntSign") || "",
      officialType: [2, 3, 7, 13, 16, 17, 19, 20, 21, 22, 23, 24, 25, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 73, 77, 78, 79, 80, 81, 82, 87, 90, 92, 93, 96, 97, 98, 99, 100, 101, 102, 106]
    }
  },
  methods: {
    // 优选 官方重新下单
    dispatchSend(data, type) {
      const sendman = {
        name: data.sendName,
        phone: data.sendmobile,
        fixedPhone: data.sendtel,
        xzqName: `${data.sendxzq.split('#').join(',')}`,
        address: data.sendaddr
      }
      const recxzq = data.recxzq ? data.recxzq.split('#') : ''
      const recman = {
        name: data.recName,
        phone: data.recmobile,
        fixedPhone: data.rectel,
        xzqName: `${data.recxzq.split('#').join(',')}`,
        address: data.recaddr
      }
      localStorage.setItem('dispatch_sendman', JSON.stringify(sendman))
      if (type == 1) { // 重新下单
        localStorage.setItem('dispatch_recman', JSON.stringify(recman))
        if (data.tabId == 4 || data.tabId == 7) {
          const params = {
            cargo: data.cargo || '日用品',
            weight: data.weight || 1
          }
          localStorage.setItem('dispatch_orderData', JSON.stringify(params))
        }
      } else {
        localStorage.removeItem('dispatch_recman')
        localStorage.removeItem('dispatch_orderData')
      }
      console.log(JSON.parse(localStorage.getItem('dispatch_sendman')))
      this.navigator.replace({
        name: "dispatch",
        query: {
          orderSource: 'sendAgain'
        }
      })
    },
    getHistory(){
      this.$http.get('/apicenter/order.do?method=getOrderList', {
        data: {
          offset: 0, 
          limit: 999,
          apiversion: 14,
          sntSign: this.sntSign || "",
          orderStatusType: 0
        },
      }).then(res => {
        if(res.count > 0){
          this.lists = res.data.filter(item => {
            return item.type !== 5
          })
        } else{
          this.emptyTips = '没有您的寄件订单<br>马上寄个快递让我派上用场吧~'
        }
        this.finish = true
      }).catch(result => {
        this.emptyTips = result.message
        this.finish = true
      })
    },
    cancel(item) {
      this.$broadcastChannel.$on("updateHisotry", () => {
        this.getHistory()
      })
      this.navigator.push({
        name: "cancel",
        params: {
          event: "updateHisotry",
          order: item
        }
      })
    },
    goDetail(index) {
      var item  = this.lists[index];
      if(this.officialType.includes(item.type)) { // 官方
        this.$router.push({
          name: "dispatchOfficial",
          query: {
            expid: item.expid,
            sign: item.sign
          }
        })
      } else if (item.type === 6) { // 优选
        this.$router.push({
          name: "dispatchDetailIndex",
          query: {
            expid: item.expid,
            dispatchid: item.dispatchId
          }
        })
      } else {
        this.$router.push({
          name: "normalDetail",
          query: {
            expid: item.expid,
            sign: item.sign
          }
        })
      }
    },
    sendAgain(index, cargo) {
      var item = this.lists[index]
      if(this.sntSign) {
        this.$router.go(-1)
        return
      }
      if(item.role === 'THIRD' && item.kuaidiCom !== "debangkuaidi" || item.type == 6){
        util.setSession("reOrderAddress", {
          rec: {
            xzqName: item.recxzq.replace(/#/g, ','),
            address: item.recaddr,
            phone: item.recmobile,
            name: item.recName
          },
          send: {
            xzqName: item.sendxzq.replace(/#/g, ','),
            address: item.sendaddr,
            phone: item.sendmobile,
            name: item.sendName,
          }
        })
        if(cargo) {
          this.loading = this.$loading("正在加载...")
          this.showLoading = true
          this.$http.get("/apicenter/order.do?method=getOrderInfo", {
            data: {
              dispatchid: item.dispatchId,
              expid: item.expid
            }
          }).then(result => {
            var detail = result.detail
            cargo && util.setSession('reOrderCargo', {
              cargodesc: detail.cargodesc ? detail.cargodesc.split(",") : [],
              cargo: {
                name: detail.cargo
              },
              weight: detail.weight,
              valins: detail.valinspay
            })
            util.setSession("reorder","do")
            this.loading.hide()
            this.navigator.push({
              name: "dispatch"
            })
          }).catch(() => {
            this.loading.hide()
          })
        } else {
          util.setSession("reorder","do")
          this.navigator.push({
            name: "dispatch"
          })
        }
      } else if(item && item.sign) {
        if(+item.kdrec == 0) {
          this.$confirm({
            content: '该快递员最近30天收件质量低于平台要求，是否继续下单',
            confirmText:"继续下单",
            cancelText: "重新选择"
          },() => {
            this.goAdd(item)
          })
        } else {
          this.goAdd(item)
        }
      }
    },
    goAdd(item) {
      var rec = item.recxzq.split('#').join(','), send = item.sendxzq.split('#').join(',')
      util.setStorage('sendAgain', {xzqName: send,address:item.sendaddr,name:item.sendName,phone:item.sendmobile})
      util.setStorage('recAgain', {xzqName:rec,name:item.recName,phone:item.recmobile,address:item.recaddr})
      util.setStorage('kd_sentcom',  item.kuaidiCom)
      util.setStorage('kd_department', item.department)
      util.setStorage('kd_gotaddr', item.gotaddr)
      util.setStorage('kd_payaccount', item.payaccount)
      let query = {
        sign: item.sign,
        sendAgain: 'do'
      }
      if(item.kuaidiCom === 'debangkuaidi') {
        query.heavy = 1
        util.setStorage("showHeavy", 1)
      }
      this.navigator.push({
        name: "normal",
        query: query
      })
    },
    goPay(index){
      var item = this.lists[index]
      if (this.officialType.includes(item.type)) { // 官方
        const paywayen = item.paywayen || ''
        const optBtn = 1
        this.navigator.push({
          name: "dispatchPayDetail",
          query: {
            expid: item.expid,
            sign: item.sign || "",
            paystatus: item.paystatus,
            paywayen: paywayen,
            optBtn: optBtn,
            com: item.kuaidiCom,
            price: item.price
          }
        })
      } else {
        this.navigator.push({
          name: "normalPay",
          query: {
            expid: item.expid,
            kuaidicom: item.kuaidiCom,
            sign: item.sign
          }
        })
      }
    },
    dispatchPay(item) {
      if (item.tabId == 0) {
        this.$broadcastChannel.$on("updateHisotry", () => {
          this.getHistory()
        })
        this.navigator.push({
          name: "dispatchPay",
          query: {
            expid: item.expid,
            dispatchid: item.dispatchId || ""
          },
          params: {
            source: 'orderList'
          }
        })
      } else {
        this.$broadcastChannel.$on("updateHisotry", () => {
          this.getHistory()
        })
        const paywayen = item.paywayen || ''
        const optBtn = 1
        this.navigator.push({
          name: "dispatchPayDetail",
          query: {
            expid: item.expid,
            dispatchid: item.dispatchId || "",
            paystatus: item.paystatus,
            paywayen: paywayen,
            optBtn: optBtn
          }
        })
      }
    },
    reminder(item) {
      this.$http.post("/apicenter/order.do?method=reminder", {
        data: {
          expid: item.expid
        }
      }).then(() => {
        this.$toast("已催单", 1000)
      })
    },
  },
  filters:{
    calctime: function(time) {
      if(!time) return ''
      var timeStr = time.replace(/-/g,'/');
      var dateObj = new Date(timeStr), now = new Date()
      var timeObj = {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate(),hours: dateObj.getHours(), minutes: dateObj.getMinutes()},
        nowObj = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()},
        timestamps = Math.floor((now.getTime() - dateObj.getTime()) / 1000)

      if(timestamps < 60){
        return '刚刚'
      } else if(timestamps < 3600){
        return ~~(timestamps / 60) + '分钟前'
      } else if(nowObj.year == timeObj.year && nowObj.month == timeObj.month && nowObj.day - timeObj.day == 0){
        return '今天' + timeObj.hours + ':' + timeObj.minutes
      } else if(nowObj.year == timeObj.year && nowObj.month == timeObj.month && nowObj.day - timeObj.day == 1){
        return '昨天' + timeObj.hours + ':' + timeObj.minutes
      } else if(nowObj.year == timeObj.year && nowObj.month == timeObj.month && nowObj.day - timeObj.day == 2){
        return '前天' + timeObj.hours + ':' + timeObj.minutes
      } else if(nowObj.year == timeObj.year){
        return timeObj.month + '月' + timeObj.day + '日'
      } else {
        return timeObj.year + '年' + timeObj.month + '月' + timeObj.day + '日'
      }
    }
  },
  created() {
    this.login(() => {
      this.getHistory()
    }, () => {
      this.goLogin(true)
    })
  }
}